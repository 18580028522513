.gallery-page {
    padding: 20px;
    padding-bottom: 60px;
}

.page-title {
    text-align: center;
    margin-bottom: 30px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

@media (max-width: 800px) {
    .gallery-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}
