.server-status {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 5px;
}

.status-indicator {
    display: inline-flex;
    align-items: center;
}

.status-indicator::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
}

.status-indicator.online::before {
    background-color: #2ecc71;
    box-shadow: 0 0 5px #2ecc71;
}

.status-indicator.offline::before {
    background-color: #e74c3c;
    box-shadow: 0 0 5px #e74c3c;
} 