.game-card {
    background-color: var(--card-inner-background);
    border: 1px solid var(--border-color);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
    transition: transform 0.2s, background-color 0.2s, color 0.2s;
    display: flex;
    flex-direction: column;
}

.game-card:hover {
    transform: scale(1.05);
}

.thumbnail-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background-color: #000; /* You can change this to var(--background-color) if needed */
}

.thumbnail-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--play-button-background);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play-button span {
    color: #fff;
    font-size: 30px;
}

h3 {
    color: var(--pure-white);
    margin: 10px 0;
}

.description-box {
    background-color: var(--secondary-background-color);
    border-radius: 4px;
    padding: 10px;
    margin: 0 10px 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.description-box p {
    color: var(--pure-white);
    font-size: 0.9em;
    margin: 0;
}

/* Add styles for locked games */
.game-card.locked {
  position: relative;
  opacity: 0.7;
}

.lock-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--pure-white);
}

.login-text {
    padding: 8px 16px;
    border: 2px solid #00ff00;
    border-radius: var(--radius-sm);
    color: #00ff00;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 255, 0, 0.3);
}

.locked {
  opacity: 0.8;
  cursor: pointer;
}

.locked:hover {
  opacity: 1;
}

.play-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s;
}

.game-card:hover .play-overlay {
    opacity: 1;
}

.server-status-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 4px;
}

.server-status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 5px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
}


