.gallery-item {
    background-color: var(--card-background);
    border-radius: var(--radius-md);
    overflow: hidden;
    transition: transform var(--transition-fast);
    height: 100%;
    display: flex;
    flex-direction: column;
}

.gallery-item:hover {
    transform: scale(1.02);
}

.thumbnail-container {
    position: relative;
    padding-top: 56.25%;
    background-color: var(--pure-black);
}

.thumbnail-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.overlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.7)
    );
    opacity: 0;
    transition: opacity var(--transition-normal);
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-item:hover .overlay {
    opacity: 1;
}

.item-info {
    padding: var(--spacing-md);
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.item-info h3 {
    color: var(--pure-white);
    margin-bottom: var(--spacing-sm);
}

.description-box {
    background-color: var(--card-background);
    border-radius: var(--radius-sm);
    padding: var(--spacing-md);
    margin-top: var(--spacing-sm);
}

.description-box p {
    color: var(--pure-white);
    line-height: 1.4;
    font-size: 1rem;
    margin: 0;
}

.gallery-item:hover .description-box p {
    opacity: 1;
}
