@import '../styles/shared.css';

.user-details-page {
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
}

.header-section {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}

.back-button {
  padding: 8px 16px;
  background-color: var(--primary-color);
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
}

.back-button:hover {
  background-color: var(--primary-color-dark);
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.stats-card {
  background-color: var(--card-background-color);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stats-card h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--text-color-primary);
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 10px;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
}

.role-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  text-transform: capitalize;
}

/* Role Badge Colors */
.role-badge.admin {
  background-color: #ff4444;
  color: white;
}

.role-badge.subscriber {
  background-color: #4CAF50;
  color: white;
}

.role-badge.user {
  background-color: #2196F3;
  color: white;
}

/* Additional styles as needed */ 