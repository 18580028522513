.error-container {
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 4px;
  background-color: rgba(255, 68, 68, 0.1);
  border: 1px solid rgba(255, 68, 68, 0.3);
}

.error-content {
  color: #ff4444;
}

.error-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.error-code {
  font-family: monospace;
  font-size: 0.9rem;
}

.error-message {
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0 0.5rem;
}

.error-details {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  white-space: pre-wrap;
  background: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 2px;
} 