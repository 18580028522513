@import '../styles/shared.css';

.profile-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  color: var(--text-color);
}

.header-section {
  margin-bottom: 30px;
}

.header-section h1 {
  color: var(--text-color-primary);
  font-size: 2rem;
  margin: 0;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.stats-card {
  background-color: var(--card-background-color);
  border-radius: 8px;
  padding: 20px;
  box-shadow: var(--card-shadow);
}

.stats-card h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--text-color-primary);
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 10px;
  font-size: 1.5rem;
}

.stat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid var(--border-color);
}

.stat-item:last-child {
  border-bottom: none;
}

.stat-item span:first-child {
  color: var(--text-color-secondary);
  font-weight: 500;
}

.stat-item span:last-child {
  color: var(--text-color);
  font-weight: 600;
}

.role-badge {
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  text-transform: capitalize;
  font-weight: 500;
}

.role-badge.admin {
  background-color: #ff4444;
  color: white;
}

.role-badge.subscriber {
  background-color: #4CAF50;
  color: white;
}

.role-badge.user {
  background-color: #2196F3;
  color: white;
}

.status-badge {
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
}

.status-badge.green {
  background-color: #4CAF50;
  color: white;
}

.status-badge.red {
  background-color: #ff4444;
  color: white;
}

.status-badge.orange {
  background-color: #FF9800;
  color: white;
}

.status-badge.gray {
  background-color: #9E9E9E;
  color: white;
}

/* Loading State */
.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2rem;
  color: var(--text-color-secondary);
}

/* Error Message */
.error-message {
  background-color: #ffebee;
  border: 1px solid #ffcdd2;
  border-radius: 4px;
  padding: 16px;
  margin: 20px 0;
  color: #c62828;
}

.error-message button {
  background-color: #ef5350;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
}

.error-message button:hover {
  background-color: #e53935;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }

  .header-section h1 {
    font-size: 1.5rem;
  }

  .stats-card h2 {
    font-size: 1.2rem;
  }

  .stat-item {
    font-size: 0.9rem;
  }
  .danger-zone {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid #ff4444;
    border-radius: 4px;
  }
  
  .delete-button {
    background-color: #ff4444;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #cc0000;
  }
  
  .delete-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #ff4444;
    margin-top: 0.5rem;
  }
}