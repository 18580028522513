.admin-dashboard {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.active-users-summary,
.visitor-stats,
.current-stats,
.aggregate-stats,
.period-stats {
  background: var(--card-background-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.stats-grid {
  display: grid;
  gap: var(--spacing-lg);
}

.stats-section {
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
}

.stats-section h3 {
  margin-bottom: var(--spacing-md);
  color: var(--text-primary);
  font-size: 1.2rem;
}

.stats-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--spacing-md);
}

.stat-card {
  background: var(--background-primary);
  padding: var(--spacing-lg);
  border-radius: var(--radius-md);
  text-align: center;
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-card.highlight {
  background: linear-gradient(145deg, var(--primary-color-light), var(--primary-color));
  color: var(--pure-white);
}

.stat-card.highlight .stat-label {
  color: var(--pure-white);
  opacity: 0.9;
}

.table-container {
  overflow-x: auto;
  background: var(--background-primary);
  border-radius: var(--radius-md);
  margin-top: var(--spacing-md);
}

.historical-table {
  width: 100%;
  border-collapse: collapse;
}

.historical-table th,
.historical-table td {
  padding: var(--spacing-md);
  text-align: right;
}

.historical-table th:first-child,
.historical-table td:first-child {
  text-align: left;
}

.active-users-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.active-user-item {
  background: var(--background-color);
  padding: 0.75rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}

.historical-stats table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background: var(--card-background-color);
  border-radius: 6px;
  overflow: hidden;
}

.historical-stats th,
.historical-stats td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.historical-stats th {
  background: var(--background-color);
  font-weight: 600;
}

.historical-stats tr:hover {
  background: var(--background-hover-color);
}

.stat-card {
  background: var(--background-color);
  padding: 1rem;
  border-radius: 6px;
  border: 1px solid var(--border-color);
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-secondary-color);
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 2rem;
  background: var(--card-background-color);
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
}

.user-table th,
.user-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
}

.user-table th {
  background: var(--background-color);
  font-weight: 600;
}

.user-table tr:hover {
  background: var(--background-hover-color);
}

.user-table select {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  background: var(--background-color);
  color: var(--text-color);
}

.user-table button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background: var(--primary-color);
  color: white;
  cursor: pointer;
}

.user-table button:hover {
  background: var(--primary-hover-color);
}

.admin-dashboard.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1.2rem;
  color: #666;
}

.game-analytics {
  margin-top: var(--spacing-xl);
  background: var(--background-secondary);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
}

.game-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: var(--spacing-lg);
  margin-top: var(--spacing-md);
}

.game-stat-card {
  background: var(--background-primary);
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-sm);
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm);
  background: var(--background-secondary);
  border-radius: var(--radius-sm);
  margin-bottom: var(--spacing-xs);
} 