.tutorials-page {
    padding: 20px;
    padding-bottom: 100px;
}

.page-title {
    text-align: center;
    margin-bottom: 30px;
}

.tutorial-section {
    margin-bottom: 40px;
}

.tutorial-item {
    margin-bottom: 20px;
}

.tutorial-item h3 {
    margin-bottom: 10px;
}

.tutorial-item iframe {
    width: 100%;
    height: 500px;
    border: none;
}
