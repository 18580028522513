.checkout-form {
  width: 100%;
  margin-top: 20px;
}

.card-element-container {
  padding: 15px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background-color: var(--background-color);
  margin-bottom: 20px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.subscribe-button {
  width: 100%;
  padding: 15px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.subscribe-button:hover {
  background-color: var(--primary-color-dark);
}

.subscribe-button:disabled {
  background-color: var(--border-color);
  cursor: not-allowed;
}

.error-message {
  color: #ff4444;
  margin: 10px 0;
  padding: 10px;
  background-color: rgba(255, 68, 68, 0.1);
  border-radius: 4px;
  text-align: center;
}

/* Add these styles to ensure proper contrast in both themes */
.StripeElement {
  width: 100%;
  padding: 10px 0;
}

.StripeElement--focus {
  border-color: var(--primary-color);
} 