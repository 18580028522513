@import '../styles/shared.css';

.auth-page {
  padding: var(--spacing-xl);
  max-width: 400px;
  margin: 0 auto;
}

.auth-card {
  background-color: var(--pure-black);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  max-width: 320px;
  margin: 0 auto;
}

.auth-card h2 {
  margin-top: 0;
  margin-bottom: var(--spacing-lg);
  color: var(--neon-blue);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: var(--spacing-md);
}

.form-group {
  margin-bottom: var(--spacing-md);
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.form-group label {
  display: block;
  margin-bottom: var(--spacing-sm);
  color: var(--pure-white);
}

.form-group input {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-sm);
  background-color: var(--card-background);
  color: var(--pure-white);
  font-size: 0.9em;
}

.submit-button {
  width: 90%;
  margin: 0 auto;
  display: block;
  padding: var(--spacing-md);
  background-color: var(--primary-blue);
  color: var(--pure-white);
  border: none;
  border-radius: var(--radius-sm);
  cursor: pointer;
  font-size: 1em;
  transition: background-color var(--transition-fast);
}

.submit-button:hover {
  background-color: var(--secondary-blue);
}

.error-message {
  color: #ff4444;
  margin: 10px 0;
  padding: 10px;
  background-color: rgba(255, 68, 68, 0.1);
  border-radius: 4px;
  text-align: center;
}

.auth-links {
  margin-top: var(--spacing-lg);
  text-align: center;
  border-top: 1px solid var(--border-color);
  padding-top: var(--spacing-lg);
}

.auth-links a {
  color: var(--neon-blue);
  text-decoration: none;
}

.auth-links a:hover {
  text-decoration: underline;
}

.form-group input:focus {
  outline: none;
  border-color: var(--neon-blue);
  box-shadow: 0 0 0 2px rgba(0, 179, 255, 0.1);
}

.password-match-message {
  font-size: 0.9em;
  margin-top: 5px;
  padding: 5px 8px;
  border-radius: 4px;
}

.password-match-message.success {
  color: #4CAF50;
  background-color: rgba(76, 175, 80, 0.1);
}

.password-match-message.error {
  color: #ff4444;
  background-color: rgba(255, 68, 68, 0.1);
} 