/* GalleryViewer.css */
.gallery-viewer {
    position: relative;
    padding: 20px;
    text-align: center;
    width: 100%;
    height: 100vh;
    justify-content: top;
}

.close-button {
    position: absolute;
    top: 50px;
    right: 50px;
    padding: 15px;
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
}

.viewer-content {
    width: 100%;
    margin: 0 auto;
    position: relative;
    /* Base size - will be overridden by device-specific classes */
    height: 70vh;
}

/* High-res desktop */
.viewer-content.high-res {
    max-width: 1600px;
    height: 75vh;
}

/* Standard desktop */
.viewer-content.standard {
    max-width: 1200px;
    height: 70vh;
}

/* Tablet */
.viewer-content.tablet {
    max-width: 900px;
    height: 60vh;
}

/* Mobile */
.viewer-content.mobile {
    max-width: 100%;
    height: 50vh;
}

/* Mobile landscape */
.viewer-content.mobile-landscape {
    max-width: 100%;
    height: 85vh;
}

.media-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--pure-black);
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-content img,
.media-content video,
.media-content iframe {   
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    background-color: var(--pure-black);
}

.media-description {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--secondary-background-color);
    border-radius: 4px;
    color: var(--pure-white);
}

.media-description p {
    margin: 0;
    font-size: 1.5rem;
    color: var(--text-color);
}

.nav-button {
    position: absolute;
    top: 50%;
    background-color: rgba(0,0,0,0.5);
    border: none;
    color: #fff;
    padding: 1px;
    font-size: 4rem;
    cursor: pointer;
    transform: translateY(-50%);
    z-index: 10;
}

.prev-button {
    left: 0;
}

.next-button {
    right: 0;
}

.nav-button:hover {
    background-color: rgba(0,0,0,0.8);
}

.thumbnail-gallery {
    display: flex;
    overflow-x: auto;
    margin-top: 40px;
}

.thumbnail-item {
    flex: 0 0 auto;
    margin-right: 10px;
    cursor: pointer;
    border: 2px solid transparent;
}

.thumbnail-item.active {
    border-color: var(--primary-color);
}

.thumbnail-item img {
    width: 200px;
    height: 120px;
    object-fit: cover;
}

@media (max-width: 768px) {
    .viewer-content {
        height: 75vh;
    }
}

/* Add aspect ratio container for YouTube embeds */
.media-content.youtube-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    height: 0;
    overflow: hidden;
}

.media-content.youtube-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
