:root {
  /* Base Colors */
  --primary-blue: #007AFF;
  --secondary-blue: #0056b3;
  --midnight-blue: #1a1f3c;
  --pure-black: #000000;
  --off-white: #f8f9fa;
  --pure-white: #ffffff;
  --neon-blue: #00b3ff;
  
  /* Theme Colors - Dark Only */
  --background-color: var(--pure-black);
  --card-background: #111111;
  --text-primary: var(--pure-white);
  --text-secondary: #90caf9;
  --border-color: #333333;
  --hover-color: rgba(0, 179, 255, 0.1);
  --heading-color: var(--neon-blue);
  
  /* Functional Colors */
  --success: #10B981;
  --warning: #F59E0B;
  --error: #EF4444;
  
  /* Spacing */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 1.5rem;
  --spacing-xl: 2rem;
  
  /* Border Radius */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  
  /* Transitions */
  --transition-fast: 0.2s ease;
  --transition-normal: 0.3s ease;
}

/* Global Styles */
body {
  background-color: var(--background-color);
  color: var(--text-primary);
  transition: background-color var(--transition-normal),
              color var(--transition-normal);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  color: var(--heading-color);
}

/* Card Component Base */
.card {
  background-color: var(--card-background);
  border: 1px solid var(--border-color);
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}
