/* GameViewer.css */

.game-viewer {
    position: relative;
    width: 100%;
    max-width: 1625px;
    margin: 0 auto;
    text-align: center;
}

.close-button {
    position: absolute;
    top: -50px;
    right: 0;
    background-color: #ff3333;
    color: #fff;
    border: none;
    padding: 15px 20px;
    font-size: 18px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10;
}

.close-button:hover {
    background-color: #cc0000;
}

iframe {
    width: 100%;
    border: none;
    margin-bottom: 20px;
}

.instructions-container {
    margin-top: 20px;
    text-align: left;
    background-color: var(--pure-black);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-md);
    padding: var(--spacing-lg);
    color: var(--pure-white);
}

.toggle-instructions {
    background-color: var(--card-background);
    color: var(--pure-white);
    border: 1px solid var(--border-color);
    padding: var(--spacing-md) var(--spacing-lg);
    font-size: 1rem;
    border-radius: var(--radius-sm);
    cursor: pointer;
    margin-bottom: var(--spacing-md);
    transition: background-color var(--transition-fast);
}

.toggle-instructions:hover {
    background-color: var(--hover-color);
}

.instructions {
    margin-top: var(--spacing-md);
    color: var(--pure-white);
}

.instructions h3 {
    color: var(--neon-blue);
    margin-top: 0;
}
