/* GamesPage.css */

.games-page {
    padding: var(--spacing-xl);
    background-color: var(--background-color);
}

.page-title {
    text-align: center;
    margin-bottom: var(--spacing-xl);
    color: var(--neon-blue);
    font-size: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.game-sections {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    max-width: 1400px;
    margin: 0 auto;
}

.game-section {
    background-color: var(--card-background);
    border: 1px solid var(--border-color);
    border-radius: var(--radius-lg);
    overflow: hidden;
    transition: transform var(--transition-normal);
}

.game-section:hover {
    transform: translateY(-4px);
}

.section-title {
    background-color: var(--pure-black);
    color: var(--neon-blue);
    padding: var(--spacing-lg);
    margin: 0;
    cursor: pointer;
    user-select: none;
    font-size: 1.5rem;
    letter-spacing: 1px;
    border-bottom: 1px solid var(--border-color);
}

.game-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: var(--spacing-lg);
    padding: var(--spacing-xl);
    background-color: var(--card-background);
}

.game-viewer-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
}
