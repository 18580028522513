.subscription-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.subscription-page h1 {
  margin-bottom: 30px;
  color: var(--primary-color);
}

.subscription-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.subscription-card {
  background-color: var(--card-background-color);
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.subscription-card h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--primary-color);
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 10px;
}

.benefits-list {
  list-style: none;
  padding: 0;
  margin: 0 0 30px 0;
}

.benefits-list li {
  padding: 12px 0;
  border-bottom: 1px solid var(--border-color);
  position: relative;
  padding-left: 30px;
}

.benefits-list li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: var(--primary-color);
  font-weight: bold;
}

.pricing-info {
  text-align: center;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-top: 20px;
}

.pricing-info h3 {
  margin: 0 0 15px 0;
  color: var(--primary-color);
}

.price-tag {
  font-size: 2em;
  font-weight: bold;
  color: var(--primary-color);
  margin: 10px 0;
}

.price-note {
  color: var(--text-color-secondary);
  font-size: 0.9em;
  margin: 5px 0 0 0;
}

.checkout-card {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .subscription-grid {
    grid-template-columns: 1fr;
  }
} 